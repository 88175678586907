import React from "react";
import ClientSection from "../../Home/ClientSection/ClientSection";
import SecurityServiceBannerSection from "./SecurityServiceBannerSection/SecurityServiceBannerSection";
import SecurityOurServiceSection from "./SecurityOurServiceSection/SecurityOurServiceSection";
import SecurityServiceOverviewSection from "./SecurityServiceOverviewSection/SecurityServiceOverviewSection";
import SecurityServiceExcellenceSection from "./SecurityServiceExcellenceSection/SecurityServiceExcellenceSection";
import SecurityServiceFAQsSection from "./SecurityServiceFAQsSection/SecurityServiceFAQsSection";
import SecurityOurCoreServiceSection from "./SecurityOurCoreServiceSection/SecurityOurCoreServiceSection";

const SecurityServices = () => {
  return (
    <>
      <SecurityServiceBannerSection />
      <SecurityServiceOverviewSection />
      <SecurityOurServiceSection />
      <SecurityOurCoreServiceSection />
      <SecurityServiceExcellenceSection />
      {/* <SecurityServiceFAQsSection /> */}
      {/* <ClientSection /> */}
    </>
  );
};

export default SecurityServices;

{
  /* <TopBar />
      <SecurityServiceBannerSection />
      <SecurityServiceOverviewSection />
      <SecurityOurServiceSection />
      <SecurityServiceExcellenceSection />
      <SecurityServiceFAQsSection />
      <ClientSection />
      <Footer /> */
}
