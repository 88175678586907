import React from 'react'
import BannerCard from '../../About/Banner/BannerCard'

const IndustriesBannerSection = () => {
  return (
    <div>
      <BannerCard heading="Industries We Serve" />
    </div>
  )
}

export default IndustriesBannerSection
