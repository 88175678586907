import React from "react";

import SecurityServices from "./SecurityServices/SecurityServices";
import FireSafetyServices from "./FireSafetyServices/FireSafetyServices";
// import { Outlet } from "react-router";

const Service = () => {
  return (
    <div>
      {/* <Outlet/> */}
      {/* <SecurityServices /> */}
      {/* <FireSafetyServices /> */}
    </div>
  );
};

export default Service;
