import React from "react";
import PageAboutExperience from "../../../About/AboutExperience/PageAboutExperience";

const CaseMgmtServiceExcellenceSection = () => {
  const experienceTitle = "Completely Secure and Efficient Cash Management";
  const experienceDescription =
    "We consider security of your cash our responsibility and hence put in all our efforts to ensure a smooth, secure and precise cash transit. Our skilled guards are at their best in assuring a seamless transfer of cash whenever needed. ";
  const locationsLabel = "Clients";
  const employeesLabel = "Trained Taskforce";
  const clientsLabel = "Customer Satisfaction ";
  const locationsCount = "XX+";
  const employeesCount = "XXX+";
  const clientsCount = ">95%";
  return (
    <div>
      <PageAboutExperience
        experienceTitle={experienceTitle}
        experienceDescription={experienceDescription}
        locationsLabel={locationsLabel}
        employeesLabel={employeesLabel}
        clientsLabel={clientsLabel}
        locationsCount={locationsCount}
        employeesCount={employeesCount}
        clientsCount={clientsCount}
      />
    </div>
  );
};

export default CaseMgmtServiceExcellenceSection;
