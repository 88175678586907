import React from "react";
import Banner from "./Banner/Banner";
import CompanyOverview from "./CompanyOverview/CompanyOverview";
// import PageAboutExperience from "./AboutExperience/PageAboutExperience";
import OurJourney from "./OurJourney/OurJourney";
import OurTeam from "./OurTeam/OurTeam";
import Awards from "./Awards/Awards";
import AboutExperience from "./AboutExperience/AboutExperience";
import { Link } from "react-router-dom";
import AboutDirector from "./AboutDirector/AboutDirector";

const About = () => {
  return (
    <div>
      <Banner />
      <CompanyOverview />
      <AboutExperience />
      <AboutDirector/>
      <OurJourney />
      <OurTeam />
      <Awards />
    </div>
  );
};

export default About;
 