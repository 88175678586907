import React, { useState, useRef } from "react";

import home_chess from "../../../../assets/icons/home_chess.svg";

import "./FacilityMgmtOurServiceSection.css";

import up_icon from "../../../../assets/icons/our_service_section_left_side_up_icon.svg";
import down_icon from "../../../../assets/icons/our_service_section_left_side_down_icon.svg";

const services = {
  "General Housekeeping & Mechanized Cleaning": {
    title: "General Housekeeping & Mechanized Cleaning",
    content: (
      <>
        <p>
          As an integral part of our facility management services, we offer
          effective housekeeping services that assist in upkeeping a healthy and
          comfortable environment. Our competent taskforce understands how
          effective housekeeping can mitigate risky hazards and the importance
          of having a well-kept infrastructure that can breathe calm, sanitation
          and safety. Our comprehensive housekeeping services focus on a range
          of duties that must be fulfilled to maintain and manage the facility’s
          ambience.
        </p>
      </>
    ),
  },
  "Building Maintenance & Management": {
    title: "Building Maintenance & Management",
    content: (
      <>
        <p>
          We take up end-to-end contracts of facilitating maintenance and
          management of buildings through which we coordinate all activities
          that are pertaining to the maintenance, repair, improvisation of the
          buildings, be it personal home or office premises. Our management
          activities are completely focussed on the important role that this
          service area plays in ensuring a secure, safe and comfortable
          environment, be it residential, commercial, hospitals, government
          buildings and more.
        </p>
      </>
    ),
  },
  "Engineering/Electrical Maintenance": {
    title: "Engineering/Electrical Maintenance",
    content: (
      <>
        <p>
          At Checkmate, we understand that the best way to avoid high-end
          expenses in any type of engineering or electrical equipment is to
          maintain it regularly. Our service offering includes everything, right
          from mechanism to assessment of equipment, to ensure a
          well-functioning unit that keeps going good for a longer period. Our
          service engineers are well-trained in all aspects of engineering and
          electrical maintenance.
        </p>
      </>
    ),
  },
  "Pest Control": {
    title: "Pest Control",
    content: (
      <>
        <p>
          As a one stop solution provider, we are engaged in offering
          high-quality pest control services for all locations, be it homes,
          offices, buildings, sites or any other. Our pest control services are
          offered by a skilled team of experts who understand the nuances of
          this service offering and hence offer their best. We also have regular
          maintenance contracts that are proactive and needs no reminder. This
          ensures complete protection of spaces from the attack of pests.
        </p>
      </>
    ),
  },
  "Waste Management": {
    title: "Waste Management",
    content: (
      <>
        <p>
          We come up with smarter and innovative ways of collecting, managing,
          disposing and recycling waste, as an independent service offering.
          Ensuring environmental sustainability is our prime focus and our
          experts leave no stone unturned in implementing waste management
          solutions that can suffice. Our trash management solutions are meant
          to ease the garbage collection issues that occur at different
          residential and commercial premises.
        </p>
      </>
    ),
  },
  "Landscaping & Horticulture": {
    title: "Landscaping & Horticulture",
    content: (
      <>
        <p>
          One of our facility management services is to offer landscaping and
          horticulture facilities. We are skilled in designing urban areas with
          good-looking and environment friendly plants and landscaping. We also
          indulge in growing garden plants for pre-defined locations. We
          understand that this is a specific skill that must be inculcated in
          the taskforce, and we make sure our teams are well-training in this
          arena.
        </p>
      </>
    ),
  },

  "Front-Facade Cleaning & High-Rise Glass Cleaning": {
    title: "Front-Facade Cleaning & High-Rise Glass Cleaning",
    content: (
      <>
        <p>
          We offer a niche facility management service – front-façade and
          high-rise glass cleaning service, completely understanding the risks
          involved and preparing our taskforce for that. However risky it may
          be, it is imperative to have your front parts and high rises clean and
          attractive. Our cleaning services provide a pristine surface, no
          matter how tall your building is or how difficult it is to get to the
          glass surfaces.
        </p>
      </>
    ),
  },
  "Front Desk Management": {
    title: "Front Desk Management",
    content: (
      <>
        <p>
          We are competent in offering front desk management services that
          include recruitment of reservation managers, front office managers,
          clerks, concierge, administrative managers etc. Our taskforce is
          experienced and possess the niche of garnering client satisfaction
          with their apt communication strategies and disciplined approach.
        </p>
      </>
    ),
  },
  "Contractual Staffing & Drivers": {
    title: "Contractual Staffing & Drivers",
    content: (
      <>
        <p>
          We understand the need of the hour wherein organizations seek
          temporary staffing for their requirements. We offer a flexible hiring
          approach through which you can recruit employees from us on a
          temporary basis, with a well-defined contract. With all quality
          parameters intact, we offer you the best of services with your own
          defined protocols.
        </p>
      </>
    ),
  },

  "Specialized Disinfection and Sanitization Protocols": {
    title: "Specialized Disinfection and Sanitization Protocols",
    content: (
      <>
        <p>
          At Checkmate, we offer effective and quality driven sanitization
          services, disinfection processes and water tank cleaning services that
          assure you of a clean, sanitized and healthy area. We understand the
          importance of having a spotless area for maintaining health and
          hygiene. Our competent cleaners make best use of the modernized tools
          and equipment to provide fast and professional service. Be it any
          size, any capacity of the water tank, our cleaners offer their best
          cleaning and sanitization services.
        </p>
      </>
    ),
  },
  "Health & Safety Compliance Management": {
    title: "Health & Safety Compliance Management",
    content: (
      <>
        <p>
          Complying by health and safety norms is most important for locality,
          be it personal or professional. With our years of experience and
          expertise, we take up the responsibility of checking and ensuring that
          all places comply with the applied health and safety norms or not. If
          not, we help in achieving those making it a standard process.
        </p>
      </>
    ),
  },

  "Automated Hygiene Solutions": {
    title: "Automated Hygiene Solutions",
    content: (
      <>
        <p>
          Our stint with technology is a long lasting one. And we see to it that
          we implement the latest technologies while offering hygiene solutions.
          We make best use of different machineries and tools like hand drying
          machines, hand soap dispensers, monitoring machines, wipe vending
          machine etc. Our focus is on offering our end users a comfortable yet
          hygienic experience.
        </p>
      </>
    ),
  },
  "Sustainable Waste Minimization & Recycling Programs": {
    title: "Sustainable Waste Minimization & Recycling Programs",
    content: (
      <>
        <p>
          Our duty towards the environment is an undying one and hence we go by
          the Recycle – Reuse – Reduce concept. We make good use of tech
          friendly, eco friendly products that can help in lessening the carbon
          footprint and energy consumption. We help in implementing programs
          like waste management, converting waste to energy etc. that can bring
          in effective results.
        </p>
      </>
    ),
  },

  "Facility Stewardship": {
    title: "Facility Stewardship",
    content: (
      <>
        <p>
          Our competent team takes up turnkey projects which includes managing
          and monitoring the physical space, resources and all equipment of any
          premise. With an aim to ensure efficacy, safety and sustainability, it
          includes routine maintenance, space utilization, forward planning and
          guaranteeing a regular monitoring cycle.
        </p>
      </>
    ),
  },
};

const FacilityMgmtOurServiceSection = () => {
  const [selectedService, setSelectedService] = useState(
    "General Housekeeping & Mechanized Cleaning"
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const menuRef = useRef(null);
  const serviceRefs = useRef({});

  const handleMenuClick = (service) => {
    setSelectedService(service);
    setDropdownOpen(false);
    setShowContent(false);
    setTimeout(() => setShowContent(true), 5000);

    const rightSideService = document.querySelector(
      ".facility_mgmt_our_service_right_side_services"
    );

    if (rightSideService) {
      const topPosition =
        rightSideService.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerHeight / 2 - rightSideService.offsetHeight / 2;
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollMenuUp = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex > 0) {
      const prevService = Object.keys(services)[currentIndex - 1];
      setSelectedService(prevService);
      serviceRefs.current[prevService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const scrollMenuDown = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex < Object.keys(services).length - 1) {
      const nextService = Object.keys(services)[currentIndex + 1];
      setSelectedService(nextService);
      serviceRefs.current[nextService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div className="facility_mgmt_our_service_main_container">
      <div className="facility_mgmt_our_service_title">
        <p>Our</p>
        <h3>Facility Management Services</h3>
      </div>
      <div className="facility_mgmt_our_service_menu_bar">
        <div className="facility_mgmt_our_service_left_side_menu">
          <div className="dropdown_menu_container">
            <div
              className="facility_mgmt_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <button
              className="dropdown_button"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {services[selectedService].title}
            </button>

            <div
              className="facility_mgmt_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div>

          <div className="facility_mgmt_our_service_grid_container">
            {Object.keys(services).map((service) => (
              <div
                key={service}
                className={`service_item ${
                  service === selectedService ? "selected" : ""
                }`}
                onClick={() => handleMenuClick(service)}
                ref={(el) => (serviceRefs.current[service] = el)}
              >
                {/* <span
                  className={`service_dot ${
                    service === selectedService ? "dot-active" : ""
                  }`}
                ></span> */}
                {/* <div className="service_item_content">{service}</div> */}
                <div className="facility_mgmt_our_service_item_content">
                  <span className="facility_mgmt_our_service_item_dot">
                    {service === selectedService && (
                      <img src={home_chess} alt="home chess icon" />
                    )}
                  </span>
                  {service}
                </div>
              </div>
            ))}
          </div>

          {/* ICON  */}

          {/* <div className="out_service_button_icon">
            <div
              className="facility_mgmt_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <div
              className="facility_mgmt_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div> */}
        </div>

        <div className="facility_mgmt_our_service_right_side_services">
          <div className="facility_mgmt_our_service_right_side_services_title">
            <p>{services[selectedService].title}</p>
            <div className="facility_mgmt_our_service_horizontal_line"></div>
          </div>
          <div
            className={`facility_mgmt_our_service_right_side_services_content ${
              showContent ? "fade-in" : "fade-out"
            }`}
          >
            {services[selectedService].content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilityMgmtOurServiceSection;
