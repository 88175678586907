import React, { useState, useRef } from "react";
import "./SecurityOurServiceSection.css";

import up_icon from "../../../../assets/icons/our_service_section_left_side_up_icon.svg";
import down_icon from "../../../../assets/icons/our_service_section_left_side_down_icon.svg";

import home_chess from "../../../../assets/icons/home_chess.svg";

const services = {
  "Manned Guarding": {
    title: "Manned Guarding",
    content: (
      <>
        <p>
          Our human security resources are our strength. Far and wide, spread
          across most locations in the country, our manned guarding security
          services are considered one of the topmost. Our well-trained and
          robust security crew possesses the capabilities of offering physical
          guarding of properties, assets, people, with the comprehensive support
          of our large network, satellite offices and ground teams. We ensure
          complete protection against any type of theft, abnormal behaviour,
          criminal indulgence, raised alarms, assault or malicious attack.
        </p>
      </>
    ),
  },
  "Security Audits & Consultancy": {
    title: "Security Audits & Consultancy",
    content: (
      <>
        <p>
          Based on the extensive years of experience, Checkmate offers precise
          security audit and consultancy services that can be befitted to client
          requirements. We perform regular security survey and audit of
          premises, suggest changes in the entire setup for thinning the gaps
          between the physical security arrangements and system related
          measures. We ensure a collaborative approach with our client so that
          together, we can elevate their organization to a full-proof and secure
          environment.
        </p>
      </>
    ),
  },
  "Antecedent Verification": {
    title: "Antecedent Verification",
    content: (
      <>
        <p>
          We offer professional employee check and corporate investigation
          services during the pre-employment and post-employment process. Our
          specialized team of experts work on devising expert corporate
          investigation solutions that focus completely on ascertaining a
          detailed background check of the prospective/current employees. Our
          thorough analysis includes verification of the employee's details from
          their last organization and their educational institutes.
        </p>
      </>
    ),
  },
  "Technology Integrated Security": {
    title: "Technology Integrated Security",
    content: (
      <>
        <p>
          At Checkmate, we believe in staying abreast of latest technologies and
          ensure a technology driven service approach. Our security services
          include cabling, devices mounting, signal & power connections, UPS for
          back-up power, related software configuration & network implementation
          etc. We take up implementation and training work for in-house designed
          projects as well as outsourced work taken on contract. Our Annual
          Maintenance Contracts ensure prompt services and response to problems.
        </p>
      </>
    ),
  },
  "Risk Assessment & Threat Analysis": {
    title: "Risk Assessment & Threat Analysis",
    content: (
      <>
        <p>
          Our experience and expertise have enforced assessing risks and
          analysing threats, powered by effective mitigation and contingency
          plans. We have a stringent focus on preventing occurrence of defects
          and risks, to the extent possible. If it is encountered, we are
          well-prepared with a comprehensive plan that does not let the normal
          functioning get hampered. Our thought process empowers us to have a
          look at the security scenario with a wholistic view.
        </p>
      </>
    ),
  },

  "Remote Surveillance": {
    title: "Remote Surveillance",
    content: (
      <>
        <p>
          We possess the capability of observing, supervising and managing video
          cameras from a far-off distance. These cameras can be leveraged to
          securely monitor different types of locations wherein instead of
          hiring security personnel everywhere, monitoring can be done through
          remote surveillance, round-the-clock. Real-time information can be
          viewed, recorded and availed as and when necessary. Data can also be
          stored for a long time.
        </p>
      </>
    ),
  },

  "Integrated Risk Management Solutions": {
    title: "Integrated Risk Management Solutions",
    content: (
      <>
        <p>
          As a rounded approach to avail best of security services, we offer
          excellent Integrated Risk Management solutions that can monitor the
          entire risk management procedure. It offers reliable and continuous
          information to all users through different application systems. Our
          ardent taskforce is well trained in complying with industry wide
          regulations wherein data is made secure with complete governance.
        </p>
      </>
    ),
  },

  "Strategic Security Oversight": {
    title: "Strategic Security Oversight",
    content: (
      <>
        <p>
          To each its own and who will understand it better than us. We realize
          the importance of carving out strategic security measures that offer
          technical solutions and implementation guidelines as per the
          organizational requirements. We ensure role-based access and
          authentication that does not let any unauthorized personnel access
          data. We ensure that transparency gets converted into responsibility
          and complete control.
        </p>
      </>
    ),
  },

  "Holistic Security Solutions": {
    title: "Holistic Security Solutions",
    content: (
      <>
        <p>
          We at Checkmate are an experienced event security management
          organization, having a complete service offering for securing all
          types of events be it corporate, personal, promotional, parties,
          annual general meetings, exhibitions, sports events, high-profile
          weddings, music concerts, public gatherings and more. We take the
          burden off your shoulder to ensure a peaceful, relaxed and disciplined
          atmosphere all around you, so that you can extract the best out of
          your functions. Ensuring complete law and order at events is what we
          ensure, with full proof dedication.
        </p>
        {/* <span>Key Highlights</span>
        <ul>
          <li>
            Checking employee’s liabilities, financial status, performance,
            criminal records
          </li>
          <li>
            Variety of verification based on Residential, police, employment
            records
          </li>
          <li>
            Precise, reliable, quality driven and compliant verification
            solutions
          </li>
          <li>
            Vendor verification, tenant verification, executive verification
          </li>
          <li>Adherence to legal and regulatory requirements</li>
        </ul> */}
      </>
    ),
  },

  "Security Vulnerability Assessments": {
    title: "Security Vulnerability Assessments",
    content: (
      <>
        <p>
          Our testing approach is well versed with identifying the
          vulnerabilities that can hamper the success of any operation. We
          understand the different complexity and severity levels attached to
          the vulnerability. There are different manual and automated
          methodologies that can encompass the entire work stream and assess the
          outcomes with possible solutions. We, systematically, review the weak
          areas that can lead to trouble and act in advance so that those
          incidents can be avoided, well ahead of time.
        </p>
        {/* <span>Key Highlights</span>
        <ul>
          <li>
            Checking employee’s liabilities, financial status, performance,
            criminal records
          </li>
          <li>
            Variety of verification based on Residential, police, employment
            records
          </li>
          <li>
            Precise, reliable, quality driven and compliant verification
            solutions
          </li>
          <li>
            Vendor verification, tenant verification, executive verification
          </li>
          <li>Adherence to legal and regulatory requirements</li>
        </ul> */}
      </>
    ),
  },
  "Executive Protection/VIP Security": {
    title: "Executive Protection/VIP Security",
    content: (
      <>
        <p>
          We understand the baggage that comes with being an executive, who is
          exposed to heightened personal risks because of their prestige, net
          worth, connections and occupation. Hence, we offer an exclusive
          security package that comprises of all arenas related to protection of
          VIPs including celebrities, politicians, corporate seniors etc. Our
          close protection services have been embedded into our regular training
          schedules and hence our security personnels are apt in managing a
          specialized kind of security offering.
        </p>
      </>
    ),
  },

  "Critical Asset Protection Protocols": {
    title: "Critical Asset Protection Protocols",
    content: (
      <>
        <p>
          We abide by industry standard critical asset protection protocols that
          could include all areas pertaining to threat detection, security
          awareness, risk management, access control, perimeter protection. We
          assess the asset landscape of our client and derive the security needs
          that could form the basis of a security architecture. We ensure no
          critical data asset is left untouched, and our security solution turns
          out to be wholesome.
        </p>
        {/* <span>Key Highlights</span>
        <ul>
          <li>
            Checking employee’s liabilities, financial status, performance,
            criminal records
          </li>
          <li>
            Variety of verification based on Residential, police, employment
            records
          </li>
          <li>
            Precise, reliable, quality driven and compliant verification
            solutions
          </li>
          <li>
            Vendor verification, tenant verification, executive verification
          </li>
          <li>Adherence to legal and regulatory requirements</li>
        </ul> */}
      </>
    ),
  },

  "HNI Security Customisation": {
    title: "HNI Security Customisation",
    content: (
      <>
        <p>
          We understand the sensitivity that is associated while offering
          security services to High-Net-worth Individuals (HNI). Our specified
          taskforce ensures that all the involved protocols are followed while
          offering security to these chosen branches of clients. Our tech savvy
          team makes best use of latest technologies so that data is kept secure
          and can be availed only by a chosen few. We also offer private
          staffing facilities for our HNI clients.
        </p>
        {/* <span>Key Highlights</span>
        <ul>
          <li>
            Checking employee’s liabilities, financial status, performance,
            criminal records
          </li>
          <li>
            Variety of verification based on Residential, police, employment
            records
          </li>
          <li>
            Precise, reliable, quality driven and compliant verification
            solutions
          </li>
          <li>
            Vendor verification, tenant verification, executive verification
          </li>
          <li>Adherence to legal and regulatory requirements</li>
        </ul> */}
      </>
    ),
  },

  "Travel Protocol Management": {
    title: "Travel Protocol Management",
    content: (
      <>
        <p>
          Security services that are related to travel facilities are taken care
          of, with specialized offerings that focus on travel risk assessment,
          that can offer complete protection to the entire taskforce. We also
          indulge in taking insurance policies on time so that the consolidated
          travel plan remains uninterrupted and smooth. Our team works out on a
          proper crisis management procedure that can work well in case
          required.
        </p>
        {/* <span>Key Highlights</span>
        <ul>
          <li>
            Checking employee’s liabilities, financial status, performance,
            criminal records
          </li>
          <li>
            Variety of verification based on Residential, police, employment
            records
          </li>
          <li>
            Precise, reliable, quality driven and compliant verification
            solutions
          </li>
          <li>
            Vendor verification, tenant verification, executive verification
          </li>
          <li>Adherence to legal and regulatory requirements</li>
        </ul> */}
      </>
    ),
  },
};

const SecurityOurServiceSection = () => {
  const [selectedService, setSelectedService] = useState("Manned Guarding");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const menuRef = useRef(null);
  const serviceRefs = useRef({});

  const handleMenuClick = (service) => {
    setSelectedService(service);
    setDropdownOpen(false);
    setShowContent(false);
    setTimeout(() => setShowContent(true), 5000);

    const rightSideService = document.querySelector(
      ".our_service_right_side_services"
    );

    if (rightSideService) {
      const topPosition =
        rightSideService.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerHeight / 2 - rightSideService.offsetHeight / 2;
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollMenuUp = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex > 0) {
      const prevService = Object.keys(services)[currentIndex - 1];
      setSelectedService(prevService);
      serviceRefs.current[prevService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const scrollMenuDown = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex < Object.keys(services).length - 1) {
      const nextService = Object.keys(services)[currentIndex + 1];
      setSelectedService(nextService);
      serviceRefs.current[nextService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div className="our_service_main_container">
      <div className="our_service_title">
        <p>Our</p>
        <h3>Security Services</h3>
      </div>
      <div className="our_service_menu_bar">
        <div className="our_service_left_side_menu">
          <div className="dropdown_menu_container">
            <div
              className="our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <button
              className="dropdown_button"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {services[selectedService].title}
            </button>

            <div
              className="our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div>

          <div className="our_service_grid_container">
            {Object.keys(services).map((service) => (
              <div
                key={service}
                className={`service_item ${
                  service === selectedService ? "selected" : ""
                }`}
                onClick={() => handleMenuClick(service)}
                ref={(el) => (serviceRefs.current[service] = el)}
              >
                {/* <span
                  className={`service_dot ${
                    service === selectedService ? "dot-active" : ""
                  }`}
                ></span> */}
                {/* <div className="service_item_content">{service}</div> */}
                <div className="service_item_content">
                  <span className="service_item_dot">
                    {service === selectedService && (
                      <img src={home_chess} alt="home chess icon" />
                    )}
                  </span>
                  {service}
                </div>
              </div>
            ))}
          </div>

          {/* ICON  */}

          {/* <div className="out_service_button_icon">
            <div
              className="our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <div
              className="our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div> */}
        </div>

        <div className="our_service_right_side_services">
          <div className="our_service_right_side_services_title">
            <p>{services[selectedService].title}</p>
            <div className="our_service_horizontal_line"></div>
          </div>
          <div
            className={`our_service_right_side_services_content ${
              showContent ? "fade-in" : "fade-out"
            }`}
          >
            {services[selectedService].content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityOurServiceSection;
