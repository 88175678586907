import React from "react";
import "./ChooseUs.css";
import choose_us_section_dots from "../../../assets/icons/choose_us_section_dots.svg";
import choose_use_section_round from "../../../assets/icons/choose_us_section_round.svg";

const ChooseUs = () => {
  return (
    <>
      <div className="choose_us_main_container">
        <div className="choose_us_container">
          <div className="choose_us_title">
            <p>Checkmate</p>
            <h3>Why Choose Us</h3>
          </div>
          <div className="choose_us_content">
            <ul>
              <li>CSPL is a one stop shop for all Security Solutions.</li>
              <li>Cost Effective Solutions.</li>
              <li>Driven by Technology & Automation.</li>
              <li>Our solutions are flexible yet robust.</li>
              <li>Strict monitoring and 100% compliance.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseUs;
