import React from 'react'
import BannerCard from '../../../About/Banner/BannerCard'

const FacilityMgmtServiceBannerSection = () => {
  return (
    <div>
      <BannerCard heading="Facility Managment Services" />
    </div>
  )
}

export default FacilityMgmtServiceBannerSection
