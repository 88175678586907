import React from "react";
import IndustriesBannerSection from "./IndustriesBannerSection/IndustriesBannerSection";
import IndustriesOverviewSection from "./IndustriesOverviewSection/IndustriesOverviewSection";
import IndustriesWeServerSection from "./IndustriesWeServerSection/IndustriesWeServerSection";
import IndustriesExcellenceSection from "./IndustriesExcellenceSection/IndustriesExcellenceSection";
import IndustriesFAQsSection from "./IndustriesFAQsSection/IndustriesFAQsSection";

const Industries = () => {
  return (
    <div>
      <IndustriesBannerSection />
      <IndustriesOverviewSection/>
      <IndustriesWeServerSection/>
      <IndustriesExcellenceSection/>
      {/* <IndustriesFAQsSection /> */}
    </div>
  );
};

export default Industries;
// faws ma err 