import React, { useState, useRef } from "react";
import "./ElectronicOurServiceSection.css";
import home_chess from "../../../../assets/icons/home_chess.svg";

import up_icon from "../../../../assets/icons/our_service_section_left_side_up_icon.svg";
import down_icon from "../../../../assets/icons/our_service_section_left_side_down_icon.svg";

const services = {
  "Drone Surveillance System": {
    title: "Drone Surveillance System",
    content: (
      <>
        <p>
          We offer all-inclusive drone surveillance systems that make best use
          of drones - Unmanned Aerial Vehicles (UAV) for capturing videos and
          still images from a higher altitude. These systems focus on
          technologies that sense and communicate information in areas of data
          collection, protecting infringement, search and rescue, border patrol
          etc. This helps in maintaining a safe environment with proper security
          protocols, ensuring a wider coverage.
        </p>
      </>
    ),
  },
  "CCTV Surveillance System": {
    title: "CCTV Surveillance System",
    content: (
      <>
        <p>
          Our security experts believe that security and surveillance can never
          be compromised. That is where we indulge in installation of CCTV
          (Closed Circuit Television) surveillance systems as it is a must
          especially where security is at prime importance. CCTV systems are
          basically leveraged for recording and monitoring the activities of any
          premises and thereby, combat crime and unwanted access. It is
          considered ideal for preventing crime, lessening security risk and
          garnering video footage.
        </p>
      </>
    ),
  },

  "Access Control System": {
    title: "Access Control System",
    content: (
      <>
        <p>
          At Checkmate, our proactive taskforce believes in implementing
          modernized access control systems that can help buildings control who
          can enter and who cannot. This can be done with different mechanisms
          that can bring in more security, integrate your systems with others,
          get in biometrics features and let you control the entry of the wanted
          and unwanted personnels. We implement access control systems that have
          features pertaining to credentials, readers, controllers etc.
        </p>
      </>
    ),
  },
  "Network Infrastructure": {
    title: "Network Infrastructure",
    content: (
      <>
        <p>
          We offer physical network infrastructure security services through
          which we protect the physical premises of a network that includes
          related systems, devices, routers and infrastructure from unwanted
          access that can result due to factors such as terrorism, natural
          disasters, accidents, theft etc. We also take care of continual uptime
          by providing measures that can minimize the chances of a low time in
          the network.
        </p>
      </>
    ),
  },
  "Intrusion Detection System": {
    title: "Intrusion Detection System",
    content: (
      <>
        <p>
          Our teams excel in implementing Physical/Perimeter Intrusion Detection
          Systems that can manage and monitor any type of unauthorized entry in
          physical locations through intelligent usage of devices like cameras,
          doorstops, alarm sensors, motion detectors etc. These systems are also
          well integrated into perceiving environmental calamities like
          earthquakes, fire, flooding etc. It can be deployed on an any physical
          premises where it is seamlessly integrated with other security
          measures.
        </p>
      </>
    ),
  },
  "Perimeter Security Solution": {
    title: "Perimeter Security Solution",
    content: (
      <>
        <p>
          Our tech-savvy experts offer security solutions that leverage the
          physical restrictions and technologies to protect from unlawful
          access, along the boundary of a protected area. It includes a variety
          of systems that could have mobile surveillance, electronic alarms,
          long range surveillance, ground radar systems, microwave barriers,
          fibre optic detection and advanced pulse fences that prevent and
          notice intruders.
        </p>
      </>
    ),
  },
  "Premium Access Management Solutions": {
    title: "Premium Access Management Solutions",
    content: (
      <>
        <p>
          We understand the nuances attached to being an elite or popular class
          organization and hence offer premium access management solutions that
          can cater to the specialized requirements. Our modern and latest
          techniques and taskforce are perfect at fulfilling the intricate needs
          of organizations that have functionalities different from the rest. We
          control and monitor activities of these premium users, from beginning
          to end.
        </p>
      </>
    ),
  },
  "High-Security Intrusion Prevention": {
    title: "High-Security Intrusion Prevention",
    content: (
      <>
        <p>
          We implement effective Intrusion Prevention Systems that can keep on
          monitoring the network on a continuous basis, to ensure there is no
          malicious activity happening and even block the entry of any kind of
          suspicious activity, on the network be it physical or logical. We
          ensure proper action control mechanism, with cutting-edge security
          measures, for preventing the occurrence of such intrusion. Our
          solutions keep inspecting the traffic to recognize any unwanted
          identity, by verifying it against those which are ideally supposed to
          be present.
        </p>
      </>
    ),
  },
  "State-of-the-Art Alert Mechanisms": {
    title: "State-of-the-Art Alert Mechanisms",
    content: (
      <>
        <p>
          Our capable experts implement versatile alert mechanisms in different
          areas, as needed. It could consist of alerts that could get generated
          on a pre-defined interval or during any unauthorized activity through
          motion sensors, fire alarms, Bluetooth alerts, AI-embedded networks
          etc. We understand that timely alert and notification generation can
          save mishaps from happening and hence we believe in investing time and
          efforts for that.
        </p>
      </>
    ),
  },
  "High-Caliber Remote Surveillance Services": {
    title: "High-Caliber Remote Surveillance Services",
    content: (
      <>
        <p>
          Remote video surveillance is the call of today. As one of the most
          modern techniques, it indulges in implementing good quality remote
          surveillance systems that can keep a continuous track of all that is
          happening, with sufficient proof anytime of the day. With its round
          the clock accessibility, remote surveillance comes out handy and
          accurate when it comes to finding proof whenever there has been any
          undue incident.
        </p>
      </>
    ),
  },

  "High-Fidelity Biometric Authentication Systems": {
    title: "High-Fidelity Biometric Authentication Systems",
    content: (
      <>
        <p>
          Hi-fi fidelity biometric authentication systems make best usage of the
          biometric characteristics like voice and face recognition and hence
          offers a great deal of security. Our taskforce makes perfect use of
          such systems through which a thorough user authentication can be done.
          As a cybersecurity procedure, it makes use of certain user
          characteristics like facial recognition, fingerprint identification,
          keystrokes etc.
        </p>
      </>
    ),
  },
  "Encrypted Communication Networks": {
    title: "Encrypted Communication Networks",
    content: (
      <>
        <p>
          We offer completely encrypted communication network systems wherein
          two involved parties can easily communicate with each other, without
          any risk of any third party intervening in, with a malicious intent.
          We leverage best methods of encryption that involves converting data,
          integrating data, trusting users, compliance with standards like
          HIPAA, GDPR etc. End-to-end encryption is ensured by our taskforce
          keeping in the mind the sheer reason behind it.
        </p>
      </>
    ),
  },
};

const ElectronicOurServiceSection = () => {
  const [selectedService, setSelectedService] = useState(
    "Drone Surveillance System"
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const menuRef = useRef(null);
  const serviceRefs = useRef({});

  const handleMenuClick = (service) => {
    setSelectedService(service);
    setDropdownOpen(false);
    setShowContent(false);
    setTimeout(() => setShowContent(true), 5000);

    const rightSideService = document.querySelector(
      ".electronic_our_service_right_side_services"
    );

    if (rightSideService) {
      const topPosition =
        rightSideService.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerHeight / 2 - rightSideService.offsetHeight / 2;
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollMenuUp = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex > 0) {
      const prevService = Object.keys(services)[currentIndex - 1];
      setSelectedService(prevService);
      serviceRefs.current[prevService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const scrollMenuDown = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex < Object.keys(services).length - 1) {
      const nextService = Object.keys(services)[currentIndex + 1];
      setSelectedService(nextService);
      serviceRefs.current[nextService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  // electronic_our_service_main_container
  return (
    <div className="electronic_our_service_main_container">
      <div className="electronic_our_service_title">
        <p>Our</p>
        <h3>Electronic Security Services</h3>
      </div>
      <div className="electronic_our_service_menu_bar">
        <div className="electronic_our_service_left_side_menu">
          <div className="dropdown_menu_container">
            <div
              className="electronic_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <button
              className="dropdown_button"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {services[selectedService].title}
            </button>

            <div
              className="electronic_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div>

          <div className="electronic_our_service_grid_container">
            {Object.keys(services).map((service) => (
              <div
                key={service}
                className={`service_item ${
                  service === selectedService ? "selected" : ""
                }`}
                onClick={() => handleMenuClick(service)}
                ref={(el) => (serviceRefs.current[service] = el)}
              >
                {/* <span
                  className={`service_dot ${
                    service === selectedService ? "dot-active" : ""
                  }`}
                ></span> */}
                {/* <div className="service_item_content">{service}</div> */}
                <div className="electronic_our_service_item_content">
                  <span className="electronic_our_service_item_dot">
                    {service === selectedService && (
                      <img src={home_chess} alt="home chess icon" />
                    )}
                  </span>
                  {service}
                </div>
              </div>
            ))}
          </div>

          {/* ICON  */}

          {/* <div className="out_service_button_icon">
            <div
              className="electronic_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <div
              className="electronic_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div> */}
        </div>

        <div className="electronic_our_service_right_side_services">
          <div className="electronic_our_service_right_side_services_title">
            <p>{services[selectedService].title}</p>
            <div className="electronic_our_service_horizontal_line"></div>
          </div>
          <div
            className={`electronic_our_service_right_side_services_content ${
              showContent ? "fade-in" : "fade-out"
            }`}
          >
            {services[selectedService].content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectronicOurServiceSection;
