import React, { useState } from "react";
import "./IndustriesWeServerSection.css";
import right_side_arrow from "../../../assets/icons/industry_right_side_arrow.svg";
import serve_we_banking from "../../..//assets/icons/industry_serve_we_banking.svg";

import security_service_icon from "../../../assets/icons/service_section_popup_security_service.svg";
import facility_meng_icon from "../../../assets/icons/service_section_popup_facility_man.svg";
import fire_safety_iocn from "../../../assets/icons/service_section_popup_firesafety.svg";
import electronic_security_icon from "../../../assets/icons/service_section_popup_electronic_security.svg";
import cash_meng from "../../../assets/icons/service_section_popup_cash_man.svg";
import event_meng from "../../../assets/icons/service_section_popup_event_man.svg";

import industry_bank from "../../../assets/icons/industry_bank.svg";
import industry_Corporate from "../../../assets/icons/industry_Corporate.svg";
import industry_Education from "../../../assets/icons/industry_Education.svg";
import industry_FMCG from "../../../assets/icons/industry_FMCG.svg";
import industry_Hospitality from "../../../assets/icons/industry_Hospitality.svg";
import industry_Information_Technology from "../../../assets/icons/industry_Information_Technology.svg";
import industry_Manufacturing from "../../../assets/icons/industry_Manufacturing.svg";
import industry_Mining from "../../../assets/icons/industry_Mining.svg";
import industry_Petro_Chemical from "../../../assets/icons/industry_Petro_Chemical.svg";
import industry_Ports from "../../../assets/icons/industry_Ports.svg";

const serviceImages = {
  "Security Services": security_service_icon,
  "Cash Management": cash_meng,
  "Facility Management": facility_meng_icon,
  "Fire & Safety": fire_safety_iocn,
  "Electronic Security": electronic_security_icon,
  "Event Management": event_meng,
};

const industryImages = {
  Corporate: industry_Corporate,
  "Banking / BFSI": industry_bank,
  "Information Technology": industry_Information_Technology,
  Manufacturing: industry_Manufacturing,
  Education: industry_Education,
  Hospitality: industry_Hospitality,
  Ports: industry_Ports,
  Mining: industry_Mining,
  "Retail & FMCG": industry_FMCG,
  "Chemical / Petro Chemical": industry_Petro_Chemical,
};

const industriesData = [
  {
    name: "Corporate",
    description:
      "The corporate world has peculiarities and demands of its own. We are in complete sync with the major requirements ...",
    services: [
      "Security Services",
      "Facility Management",
      "Fire & Safety",
      "Electronic Security",
      "Event Management",
    ],
    popup_inner_services: "The corporate world has peculiarities and demands of its own. We are in complete sync with the major requirements that the corporate industry demands for, and our taskforce understands the nuances of serving the corporate world. All our service offerings have been serving a wide range of corporate clientele, through our experienced resources. Our services offer personalized services, peace of mind, client satisfaction, increased efficacy and maximized productivity.",
  },
  {
    name: "Banking / BFSI",
    description:
      "What matters the most in any banking or financial sector is privacy of data and sensitivity of protecting it from the outer world. ... ",
    services: [
      "Security Services",
      "Facility Management",
      "Fire & Safety",
      "Electronic Security",
      "Cash Management",
      "Event Management",
    ],
    popup_inner_services: "What matters the most in any banking or financial sector is privacy of data and sensitivity of protecting it from the outer world. Our service offerings ensure a fully protected environment that takes care of all the data that is being circulated physically and electronically. We abide by industry standards and implement cutting-edge technologies that can provide an all-round protection to customer data, mitigating risks and ensuring compliance.",
  },
  {
    name: "Information Technology",
    description:
      "Our services that apply to the Information Technology industry are in complete synchronization with the latest technologies, ...",
    services: [
      "Security Services",
      "Facility Management",
      "Fire & Safety",
      "Electronic Security",
      "Event Management",
    ],
    popup_inner_services: "Our services that apply to the Information Technology industry are in complete synchronization with the latest technologies, processes and standards that are being followed in the domain. Serving this segment needs an eye for scrutiny and an inclination towards adapting to cutting-edge systems that can help in fishing out the unwanted. Our services are highly scalable and can be increased or decreased, as needed. ",
  },
  {
    name: "Manufacturing",
    description:
      "Our diverse service portfolio serves the manufacturing industry resulting into multiple benefits like scalability, protection of ...",
    services: [
      "Security Services",
      "Facility Management",
      "Fire & Safety",
      "Electronic Security",
      "Event Management",
    ],
    popup_inner_services: "Our diverse service portfolio serves the manufacturing industry resulting into multiple benefits like scalability, protection of employees and visitors, saving on costs, efforts and time, continuous production and manufacturing with least downtime, non-indulgence of malicious or unwanted entities, prevention of physical security elements and cybersecurity threats. We also ensure protection of intellectual property and sensitive information.",
  },
  {
    name: "Education",
    description:
      "The safety and security of all involved entities is important in the education industry. Creating a safe, secure, healthy and ...",
    services: [
      "Security Services",
      "Facility Management",
      "Electronic Security",
      "Event Management",
    ],
    popup_inner_services: "The safety and security of all involved entities is important in the education industry. Creating a safe, secure, healthy and innovative environment is what is most vital to a successful education infrastructure. Our taskforce realizes how important education is to all of us and hence how critical it is to ensure that the entire operation within an educational institute operates with ease, be it security services, facility management, cash management.",
  },
  {
    name: "Hospitality",
    description:
      "The hospitality sector is all about client satisfaction, security, maintenance and management of operations. It is vital ... ",
    services: [
      "Security Services",
      "Facility Management",
      "Electronic Security",
      "Event Management",
    ],
    popup_inner_services: "The hospitality sector is all about client satisfaction, security, maintenance and management of operations. It is vital to have complete guest protection, maintain reputation of the premises and services, comply legally to all industry standards and protect all sensitive information of the client. We seek complete involvement in managing all aspects that are related to hospitality management. We ensure the premises are equipped with latest technology devices and systems.",
  },
  {
    name: "Ports",
    description:
      "Securing the ports is a completely different scenario. It does not gel with the services being offered in the other industry segments. ...",
    services: [
      "Security Services",
      "Facility Management",
      "Fire & Safety",
      "Electronic Security",
      "Event Management",
    ],
    popup_inner_services: "Securing the ports is a completely different scenario. It does not gel with the services being offered in the other industry segments. It aims towards services that protect the port from malicious attacks, offers consistent and speedy services, protects information, brings in operational efficiency by adhering to environment terms and conditions. Our domain experts are present all the time to ensure the entire community gets complete support and regular management of activities.",
  },
  {
    name: "Mining",
    description:
      "Mining sites are in remote locations and have their own specified demands that are difficult to meet. We understand the delicacy ...",
    services: [
      "Security Services",
      "Facility Management",
      "Fire & Safety",
      "Electronic Security",
      "Event Management",
    ],
    popup_inner_services: "Mining sites are in remote locations and have their own specified demands that are difficult to meet. We understand the delicacy with which mining operations must be carried out, with complete protocols to follow and productivity to ensure. Our industry experts ensure adherence to environmental standards and thereby, keep a stringent eye on implementation of latest mining rules and regulations.",
  },
  {
    name: "Retail & FMCG",
    description:
      "One of the most common industry domains where in our services have been showing their prowess is the retail and FMCG sector. ...",
    services: [
      "Security Services",
      "Facility Management",
      "Electronic Security",
      "Cash Management",
      "Event Management",
    ],
    popup_inner_services: "One of the most common industry domains where in our services have been showing their prowess is the retail and FMCG sector. Our staff is proficient in different retail operations so that they can keep monitoring inventory, assets and operations. Apart from providing security services, other services like cash management, facility management also contribute bigtime to the retail industry. Our services play a big role in reducing loss, thefts, unwanted attacks and loss of privacy.",
  },
  {
    name: "Chemical / Petro Chemical",
    description:
      "The chemical industry is a volatile one, in terms of processes and the raw material being used in it. There is a lot of ...",
    services: [
      "Security Services",
      "Fire & Safety",
      "Electronic Security",
      "Cash Management",
      "Event Management",
    ],
    popup_inner_services: "The chemical industry is a volatile one, in terms of processes and the raw material being used in it. There is a lot of stringent monitoring that is needed within. And that is what our taskforce excels at. They understand the sensitivity attached with the industry segment and offer a disciplined, monitored approach with which the entire process is well managed and maintained. There is least damage to individuals and the entire infrastructure.",
  },
];
const IndustriesWeServerSection = () => {
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleIndustryClick = (industry) => {
    setSelectedIndustry(industry);
  };

  const handleClosePopup = () => {
    setSelectedIndustry(null);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="industries_we_server_main_container">
        <div className="industries_we_server_title">
          <p>Industries We Serve</p>
        </div>

        <div className="industries_we_server_content">
          <ul className="industries-list">
            {industriesData.map((industry, index) => (
              <li
                key={index}
                className="industry_item"
                onClick={() => handleIndustryClick(industry)}
              >
                <div className="industry_bg">
                  {/* Background image is applied here */}
                  <div
                    className="industry_bg_image"
                    style={{
                      backgroundImage: `url(${industryImages[industry.name]})`,
                    }}
                  ></div>
                </div>
                <div className="industry_details">
                  <div className="industry_name">
                    <h4>{industry.name}</h4>
                    <p>{industry.description}</p>
                  </div>
                  <div
                    className={`industry_arrow_icon ${
                      selectedIndustry?.name === industry.name ? "rotated" : ""
                    }`}
                  >
                    <img src={right_side_arrow} alt="arrow icon" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {selectedIndustry && (
          <div className="industries_popup">
            <div className="industries_popup_content">
              <h3>{selectedIndustry.name}</h3>
              <p>
                {/* Lorem ipsum dolor sit amet consectetur, Nec viverra diam et cras
                lorem. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Tempore sit in ducimus omnis repudiandae suscipit dolorum,
                quibusdam accusantium voluptatibus. Impedit laboriosam ducimus
                vero exercitationem, atque fugiat nam eligendi a
                provident.______ */}
                {selectedIndustry.popup_inner_services}
                <br />
                {/* {isExpanded && (
                  <>
  
                  </>
                )}
                <button className="read_more_button" onClick={toggleExpand}>
                  {isExpanded ? "Read Less" : "Read More"}
                </button> */}
              </p>
              {selectedIndustry.services && (
                <div className="industries_services_served">
                  <h4>Services Served</h4>
                  <div className="industries_services_served_list">
                    {selectedIndustry.services.map((service, index) => (
                      <div className="service_item" key={index}>
                        <img src={serviceImages[service]} alt={service} />
                        <p>{service}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <button
                className="industries_close_popup"
                onClick={handleClosePopup}
              >
                ✕
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default IndustriesWeServerSection;
