import React, { useState, useRef } from "react";
import "./CaseMgmtOurServiceSection.css";
import home_chess from "../../../../assets/icons/home_chess.svg";

import up_icon from "../../../../assets/icons/our_service_section_left_side_up_icon.svg";
import down_icon from "../../../../assets/icons/our_service_section_left_side_down_icon.svg";

const services = {
  "Cash in Transit & Logistics": {
    title: "Cash in Transit & Logistics",
    content: (
      <>
        <p>
          We are solely responsible for secure cash conveyance - moving and
          sorting a lot of cash currency through modernized infrastructure and
          ensuring a comprehensive security protocol that manages physical and
          electronic setup.
        </p>
      </>
    ),
  },
  "Cash Processing": {
    title: "Cash Processing",
    content: (
      <>
        <p>
          Our well-trained security personals are involved in effective cash
          processing techniques that keep in mind the security and accuracy
          aspect at the tip. At our cash management centres, we ensure a precise
          and error-free processing with complete security so that there is no
          risk involved.
        </p>
      </>
    ),
  },
  "Cash Counters": {
    title: "Cash Counters",
    content: (
      <>
        <p>
          We have cash counting machines that are effective and can manage
          counting of notes in an accurate manner. These counters keep a track
          of the money involved and can be leveraged during any cash management
          process.
        </p>
      </>
    ),
  },
  "Vaulting & Custom Clearance": {
    title: "Vaulting & Custom Clearance",
    content: (
      <>
        <p>
          This is our safe and secure way of picking up and delivering cash,
          with a powerful surveillance system in place and our well-armed
          personnel ensuring complete security. Our vaults are safeguarded
          against any malicious attack, through our stringent processes.
        </p>
      </>
    ),
  },
  "Atm Cassete Change To Atm Replenishment": {
    title: "Atm Cassete Change To Atm Replenishment",
    content: (
      <>
        <p>
          We implement the lockable system – ATM Cassette Swap in which the
          entire manual process of cash replenishment is replaces by this
          innovative mechanism. This system is more secure, quality oriented and
          possesses simplified way of managing cash.
        </p>
      </>
    ),
  },
  "Cash Recyclers": {
    title: "Cash Recyclers",
    content: (
      <>
        <p>
          Adding one to the list, cash recyclers are self-service terminals that
          empower users to withdraw cash and deposit cash, without any bank
          representative present at that time. We ensure complete security and
          adherence to industry standards.
        </p>
      </>
    ),
  },
  "Bullion Transportation, Vaulting & Cearance": {
    title: "Bullion Transportation, Vaulting & Cearance",
    content: (
      <>
        <p>
          We are known for our high-service oriented offerings in the arena of
          bullion transportation, vaulting and clearance. It comprises of pick
          up, transportation, delivery and clearance with customized supply
          chain mechanisms. Our offerings have been leveraged by multiple
          industry segments like banking, finance, entertainment, logistics,
          transportation etc.
        </p>
      </>
    ),
  },

  "High-Security Cash Vaulting": {
    title: "High-Security Cash Vaulting",
    content: (
      <>
        <p>
          Our high security cash van services include cutting-edge features such
          as high-resolution camera for the entire cargo, continual monitoring
          of vehicles, types of alerts and notifications, alarm switches for any
          kind of emergency alert and more. Our well-trained guards consider
          keeping the cash safe as their foremost priority and leave no stone
          unturned in doing so.
        </p>
      </>
    ),
  },
  "Door Step Banking": {
    title: "Door Step Banking",
    content: (
      <>
        <p>
          We offer doorstep banking services that facilitate our clients with
          services at their homes, amidst strict protocols and regulations. Our
          taskforce sincerely complies with different activities like pickup and
          delivery of documents, chequebooks, cheques, financial documents, cash
          delivery, delivery of demand drafts etc. We ensure secure and robust
          doorstep banking services with a quick turnaround time.
        </p>
      </>
    ),
  },
  "Cheque Handling And Processing": {
    title: "Cheque Handling And Processing",
    content: (
      <>
        <p>
          When it comes to managing the entire process of cheque clearance and
          processing, we leave no stone unturned to provide services which are
          full proof, fast, effective and secure. Especially when it comes to
          senior citizens, we do offer a great deal of comfort and convenience.
          Our services encompass different locations and geographies, making it
          simple for clients.
        </p>
      </>
    ),
  },
  "Cash Processing Centers": {
    title: "Cash Processing Centers",
    content: (
      <>
        <p>
          Our cash management centres offer a range of services that handle cash
          in different ways, store it in different vaults, distribute cash to
          different centres like banks and ATMs, recycle cash and comply to
          industry standards and norms. It is a very important and critical
          service area and our team members are well-trained on that.
        </p>
      </>
    ),
  },
};

const CaseMgmtOurServiceSection = () => {
  const [selectedService, setSelectedService] = useState(
    "Cash in Transit & Logistics"
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const menuRef = useRef(null);
  const serviceRefs = useRef({});

  const handleMenuClick = (service) => {
    setSelectedService(service);
    setDropdownOpen(false);
    setShowContent(false);
    setTimeout(() => setShowContent(true), 5000);

    const rightSideService = document.querySelector(
      ".case_mgmt_our_service_right_side_services"
    );

    if (rightSideService) {
      const topPosition =
        rightSideService.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerHeight / 2 - rightSideService.offsetHeight / 2;
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollMenuUp = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex > 0) {
      const prevService = Object.keys(services)[currentIndex - 1];
      setSelectedService(prevService);
      serviceRefs.current[prevService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const scrollMenuDown = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex < Object.keys(services).length - 1) {
      const nextService = Object.keys(services)[currentIndex + 1];
      setSelectedService(nextService);
      serviceRefs.current[nextService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div className="case_mgmt_our_service_main_container">
      <div className="case_mgmt_our_service_title">
        <p>Our</p>
        <h3>Cash Management Services</h3>
      </div>
      <div className="case_mgmt_our_service_menu_bar">
        <div className="case_mgmt_our_service_left_side_menu">
          <div className="dropdown_menu_container">
            <div
              className="case_mgmt_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <button
              className="dropdown_button"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {services[selectedService].title}
            </button>

            <div
              className="case_mgmt_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div>

          <div className="case_mgmt_our_service_grid_container">
            {Object.keys(services).map((service) => (
              <div
                key={service}
                className={`service_item ${
                  service === selectedService ? "selected" : ""
                }`}
                onClick={() => handleMenuClick(service)}
                ref={(el) => (serviceRefs.current[service] = el)}
              >
                {/* <span
                  className={`service_dot ${
                    service === selectedService ? "dot-active" : ""
                  }`}
                ></span> */}
                {/* <div className="service_item_content">{service}</div> */}
                <div className="case_mgmt_our_service_item_content">
                  <span className="case_mgmt_our_service_item_dot">
                    {service === selectedService && (
                      <img src={home_chess} alt="home chess icon" />
                    )}
                  </span>
                  {service}
                </div>
              </div>
            ))}
          </div>

          {/* ICON  */}

          {/* <div className="out_service_button_icon">
            <div
              className="case_mgmt_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <div
              className="case_mgmt_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div> */}
        </div>

        <div className="case_mgmt_our_service_right_side_services">
          <div className="case_mgmt_our_service_right_side_services_title">
            <p>{services[selectedService].title}</p>
            <div className="case_mgmt_our_service_horizontal_line"></div>
          </div>
          <div
            className={`case_mgmt_our_service_right_side_services_content ${
              showContent ? "fade-in" : "fade-out"
            }`}
          >
            {services[selectedService].content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseMgmtOurServiceSection;
