import React from "react";
import FacilityMgmtServiceBannerSection from "./FacilityMgmtServiceBannerSection/FacilityMgmtServiceBannerSection";
import FacilityMgmtServiceOverviewSection from "./FacilityMgmtServiceOverviewSection/FacilityMgmtServiceOverviewSection";
import FacilityMgmtOurServiceSection from "./FacilityMgmtOurServiceSection/FacilityMgmtOurServiceSection";
import FacilityMgmtOurCoreServiceSection from "./FacilityMgmtOurCoreServiceSection/FacilityMgmtOurCoreServiceSection";
import FacilityMgmtServiceExcellenceSection from "./FacilityMgmtServiceExcellenceSection/FacilityMgmtServiceExcellenceSection";

const FacilityMgmtServices = () => {
  return (
    <div>
      <FacilityMgmtServiceBannerSection />
      <FacilityMgmtServiceOverviewSection />
      <FacilityMgmtOurServiceSection />
      <FacilityMgmtOurCoreServiceSection />
      <FacilityMgmtServiceExcellenceSection />
    </div>
  );
};

export default FacilityMgmtServices;
