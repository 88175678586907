import React from "react";
import PageAboutExperience from "../../../About/AboutExperience/PageAboutExperience";

const EventMgmtServiceExcellenceSection = () => {
  const experienceTitle = "Enjoyable, Safe and Seamless Event Management";
  const experienceDescription =
    "Your event is our responsibility. Our expert team of event managers ensure a smooth, secure and organized environment, with all parameters well-scrutinized and well-arranged.";
  const locationsLabel = "Clients";
  const employeesLabel = "Trained Taskforce";
  const clientsLabel = "Customer Satisfaction ";
  const locationsCount = "XX+";
  const employeesCount = "XXX+";
  const clientsCount = ">95%";
  return (
    <div>
      <PageAboutExperience
        experienceTitle={experienceTitle}
        experienceDescription={experienceDescription}
        locationsLabel={locationsLabel}
        employeesLabel={employeesLabel}
        clientsLabel={clientsLabel}
        locationsCount={locationsCount}
        employeesCount={employeesCount}
        clientsCount={clientsCount}
      />
    </div>
  );
};

export default EventMgmtServiceExcellenceSection;
