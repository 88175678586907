import React from "react";
import "./FacilityMgmtServiceOverviewSection.css";

const FacilityMgmtServiceOverviewSection = () => {
  return (
    <>
      <div className="facility_mgmt_overview_main_container">
        <div className="facility_mgmt_overview_title">
          <p>OverView</p>
          <h3>Facility Management Services</h3>
        </div>
        <div className="facility_mgmt_overview_content">
          <p>
            As a wholly owned subsidiary of Checkmate Services Private Limited,
            Checkmate Facility and Electronic Solutions Private Limited offers
            all-inclusive Facility Management services that empowers
            organizations to monitor and manage their facilities in an enhanced
            manner. We go by the ‘Man, Machine and Material’ model wherein all
            three provisions ensure that our services augment performance,
            accessibility and trustworthiness in all that we provide. Be it the
            hospitality, residential, commercial or corporate sector, we serve
            them all with equal fervour.
            <br />
            <br />
            Our highly trained and skilled manpower, industry-standard best
            practices, appropriate utilization of modern-day machines and
            techniques and focus on environment friendliness make us the most
            sought-after facility management service provider across India. We
            are instrumental in helping organizations elevate their brand image
            by ensuring a comprehensive execution of all their facilities to
            their best capabilities, under due diligence and tailored
            requirements.
          </p>
        </div>
      </div>
    </>
  );
};

export default FacilityMgmtServiceOverviewSection;
