import React from "react";
import "./Hamburger.css";
import hamburger_icon_close from "../../../assets/icons/hamburger_icon_close.svg";

const Hamburger = ({ isOpen }) => {
  const burger1Style = {
    transform: isOpen ? "rotate(39deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  };

  const burger2Style = {
    opacity: isOpen ? 0 : 1,
    transition: "opacity 0.3s ease",
  };

  const burger3Style = {
    transform: isOpen ? "rotate(-39deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  };

  return (
    <div className="hamburger">
      {isOpen ? (
        <img
          src={hamburger_icon_close}
          alt="Close Icon"
          className="close-icon"
        />
      ) : (
        <>
          <div className="burger burger1"></div>
          <div className="burger burger2"></div>
          <div className="burger burger3"></div>
        </>
      )}
    </div>
  );

  // return (
  //   <div className="hamburger">
  //     <div className="burger burger1" style={burger1Style}></div>
  //     <div className="burger burger2" style={burger2Style}></div>
  //     <div className="burger burger3" style={burger3Style}></div>
  //   </div>
  // );
};

export default Hamburger;
