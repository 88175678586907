import React, { useState, useRef } from "react";
import "./ContactUsForm.css";
import emailjs from "emailjs-com";
import contactus_call_icon from "../../../assets/icons/contactus_call_icon.svg";
import contactus_carrer_icon from "../../../assets/icons/contactus_carrer_icon.svg";
import contactus_upload_document_icon from "../../../assets/icons/contactus_upload_document_icon.svg";
import contactus_delete_icon from "../../../assets/icons/contactus_delete_icon.svg";

const ContactUsForm = () => {
  const [formType, setFormType] = useState("contact");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSwitch = (type) => {
    setFormType(type);

    setFormData({
      firstName: "",
      lastName: "",
      name: "",
      email: "",
      mobile: "",
      subject: "",
      message: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const { firstName, lastName, name, email, mobile, subject, message } =
      formData;

    const templateParams = {
      from_firstName: firstName,
      from_lastName: lastName,
      from_name: name,
      from_email: email,
      mobile: mobile,
      subject: subject,
      message: message,
    };

    const emailTemplate =
      templateParams.from_firstName == ""
        ? "template_k23yqwd"
        : "template_jtbr4e4";

    emailjs
      .send(
        "service_lmt0pqf",
        emailTemplate,
        templateParams,
        "TRftilUE6B9-OmafJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormData({
            firstName: "",
            lastName: "",
            name: "",
            email: "",
            mobile: "",
            subject: "",
            message: "",
          });

          alert("Email sent successfully!");
          setIsSubmitting(false);
        },
        (error) => {
          console.log(error.text);
          alert("Error sending email.");
          setIsSubmitting(false);
        }
      );
  };

  return (
    <>
      <div className="contactus_page_main_container">
        <div className="contactus_page_title_main_container">
          <div className="contactus_title">
            <p>Reach Out to Us for any Inquiries or Assistance</p>
          </div>
          <div className="contactus_selected_section">
            <div
              className={`contactus_callus_selected_section ${
                formType === "contact" ? "active" : ""
              }`}
              onClick={() => handleFormSwitch("contact")}
            >
              <div className="contactus_callus_icon">
                <img src={contactus_call_icon} alt="ContactUs_Calling_Icon" />
              </div>
              <p>Reach Out to Us</p>
            </div>
            <div
              className={`contactus_carrer_selected_section ${
                formType === "carrer" ? "active" : ""
              }`}
              onClick={() => handleFormSwitch("carrer")}
            >
              <div className="contactus_carrer_icon">
                <img src={contactus_carrer_icon} alt="ContactUs_Carrer_Icon" />
              </div>
              <p>Career @Checkmate</p>
            </div>
          </div>
        </div>

        {/* Form */}
        <div className="contactus_form_main_container">
          <form className="contactus_form" onSubmit={handleSubmit}>
            {formType === "contact" && (
              <div className="contactus_form_part_1">
                <div className="contactus_form_part_1_left">
                  <label>Full Name *</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="John David"
                    required
                  />
                </div>
                <div className="contactus_form_part_1_right">
                  <label>Your Email *</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder={"example@yourmail.com"}
                    required
                    pattern={
                      formType === "contact"
                        ? "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
                        : null
                    }
                  />
                </div>
              </div>
            )}
            {formType === "carrer" && (
              <div className="contactus_form_part_1">
                <div className="contactus_form_part_1_left">
                  <label>First Name *</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="John"
                    required
                  />
                </div>
                <div className="contactus_form_part_1_right">
                  <label>Last Name *</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="David"
                    required
                  />
                </div>
              </div>
            )}

            <div className="contactus_form_part_2">
              <div className="contactus_form_part_2_left">
                <label>Mobile No. *</label>
                <input
                  type="tel"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  pattern="[0-9]{10}"
                  placeholder="+91 xxxxxxxxxx"
                  required
                />
              </div>

              {formType === "contact" && (
                <div className="contactus_form_part_2_right">
                  <label>Subject *</label>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    placeholder="How can we help you"
                    required
                  />
                </div>
              )}
              {formType === "carrer" && (
                <div className="contactus_form_part_1_right">
                  <label>Your Email *</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder={"example@yourmail.com"}
                    required
                    pattern={
                      formType === "contact"
                        ? "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
                        : null
                    }
                  />
                </div>
              )}
            </div>

            <div className="contactus_form_part_3">
              <label>Message *</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Hello there, I would like to talk about how to..."
                required
              />
            </div>
            <div className="contactus_form_button">
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Send Message"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUsForm;
