import React, { useState } from "react";
import "./OurJourney.css";
import vertical_line from "../../../assets/icons/about_section_journey_vertical.svg";
import vertical_line_2 from "../../../assets/icons/about_section_journey_vertical_2.svg";
import point from "../../../assets/icons/about_section_ourjourney_point.svg";

const OurJourney = () => {
  const [popup, setPopup] = useState(null);

  // const handlePointClick = (content, popupType) => {
  //   if (popup && popup.type === popupType) {
  //     setPopup(null);
  //   } else {
  //     setPopup({ type: popupType, content });
  //     document.body.classList.add("blur");
  //   }
  // };

  const handleClickOutside = (event) => {
    if (event.target.className.includes("popup-overlay")) {
      setPopup(null);
      document.body.classList.remove("blur");
    }
  };

  const getPopupClass = () => {
    switch (popup?.type) {
      case "popup1":
        return "popup-overlay popup-content";
      case "popup2":
        return "popup-overlay popup-content2";
      case "popup3":
        return "popup-overlay popup-content3";
      case "popup4":
        return "popup-overlay popup-content4";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="ourjourney_main_container">
        <div className="ourjourney_title">
          <p>OUR</p>
          <h3>Journey</h3>
        </div>

        <div className="ourjourney_timeline">
          <div className="ourjourney_timeline_item_1">
            <h6>1986</h6>
            <div className="ourjourney_timeline_item_1_point">
              {/* <abbr title="Private security, VIP Protection and cash management services"> */}
                <img
                  src={point}
                  alt="about_section_ourjourney_point"
                  // onClick={() =>
                  //   handlePointClick(
                  //     "Private security, VIP Protection and cash management services",
                  //     "popup1"
                  //   )
                  // }
                />
              {/* </abbr> */}
            </div>
            <div className="ourjourney_timeline_item_1_content">
              <div className="ourjourney_timeline_item_1_content_vertical_line">
                <img src={vertical_line} alt="Vertical line" />
              </div>
              <p>Checkmate Detective Agency</p>
            </div>
          </div>

          <div className="ourjourney_timeline_item_2">
            <div className="ourjourney_timeline_item_2_content">
              <p>Checkmate Services Pvt. Ltd.</p>
              <div className="ourjourney_timeline_item_2_content_vertical_line">
                <img src={vertical_line_2} alt="Vertical line" />
              </div>
            </div>
            <div className="ourjourney_timeline_item_2_point">
              {/* <abbr title="Industrial, emergency and disaster management with training services."> */}
                <img
                  src={point}
                  alt="about_section_ourjourney_point"
                  // onClick={() =>
                  //   handlePointClick(
                  //     "Industrial, emergency and disaster management with training services.",
                  //     "popup2"
                  //   )
                  // }
                />
              {/* </abbr> */}
            </div>
            <h6>1992</h6>
          </div>

          <div className="ourjourney_timeline_item_3">
            <h6>1998</h6>
            <div className="ourjourney_timeline_item_3_point">
              {/* <abbr title="Housekeeping Facility Services and Electronic Security Solutions."> */}
                <img
                  src={point}
                  alt="about_section_ourjourney_point"
                  // onClick={() =>
                  //   handlePointClick(
                  //     "Housekeeping Facility Services and Electronic Security Solutions.",
                  //     "popup3"
                  //   )
                  // }
                />
              {/* </abbr> */}
            </div>
            <div className="ourjourney_timeline_item_3_content">
              <div className="ourjourney_timeline_item_3_content_vertical_line">
                <img src={vertical_line} alt="Vertical line" />
              </div>
              <p>Institute of Fire Safety & Disaster Management Studies</p>
            </div>
          </div>

          <div className="ourjourney_timeline_item_4">
            <div className="ourjourney_timeline_item_4_content">
              <p>Cash Management Service.</p>
              <div className="ourjourney_timeline_item_4_content_vertical_line">
                <img src={vertical_line_2} alt="Vertical line" />
              </div>
            </div>
            <div className="ourjourney_timeline_item_4_point">
              {/* <abbr title="Corporate Social Responsibility (CSR) activity, social welfare activities, specifically in rural areas"> */}
                <img
                  src={point}
                  alt="about_section_ourjourney_point"
                  // onClick={() =>
                  //   handlePointClick(
                  //     "Corporate Social Responsibility (CSR) activity, social welfare activities, specifically in rural areas",
                  //     "popup4"
                  //   )
                  // }
                />
              {/* </abbr> */}
            </div>
            <h6>2003</h6>
          </div>

          <div className="ourjourney_timeline_item_5">
            <h6>2008</h6>
            <div className="ourjourney_timeline_item_5_point">
              {/* <abbr title="Housekeeping Facility Services and Electronic Security Solutions."> */}
                <img
                  src={point}
                  alt="about_section_ourjourney_point"
                  // onClick={() =>
                  //   handlePointClick(
                  //     "Housekeeping Facility Services and Electronic Security Solutions.",
                  //     "popup3"
                  //   )
                  // }
                />
              {/* </abbr> */}
            </div>
            <div className="ourjourney_timeline_item_5_content">
              <div className="ourjourney_timeline_item_5_content_vertical_line">
                <img src={vertical_line} alt="Vertical line" />
              </div>
              <p>Checkmate Facility & Electronic Solution Pvt. Ltd</p>
            </div>
          </div>

          <div className="ourjourney_timeline_item_6">
            <div className="ourjourney_timeline_item_6_content">
              <p>Event Management</p>
              <div className="ourjourney_timeline_item_6_content_vertical_line">
                <img src={vertical_line_2} alt="Vertical line" />
              </div>
            </div>
            <div className="ourjourney_timeline_item_6_point">
              <abbr title="Corporate Social Responsibility (CSR) activity, social welfare activities, specifically in rural areas">
                <img
                  src={point}
                  alt="about_section_ourjourney_point"
                  // onClick={() =>
                  //   handlePointClick(
                  //     "Corporate Social Responsibility (CSR) activity, social welfare activities, specifically in rural areas",
                  //     "popup4"
                  //   )
                  // }
                />
              </abbr>
            </div>
            <h6>2021</h6>
          </div>
        </div>
        {popup && (
          <div className={getPopupClass()} onClick={handleClickOutside}>
            <div className={`popup-content${popup.type.replace("popup", "")}`}>
              <p>{popup.content}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OurJourney;
