import React from "react";
import ClientSection from "../../Home/ClientSection/ClientSection";
import FireSafetyServiceBannerSection from "./FireSafetyServiceBannerSection/FireSafetyServiceBannerSection";
import FireSafetyServiceOverviewSection from "./FireSafetyServiceOverviewSection/FireSafetyServiceOverviewSection";
import FireSafetyOurServiceSection from "./FireSafetyOurServiceSection/FireSafetyOurServiceSection";
import FireSafetyServiceExcellenceSection from "./FireSafetyServiceExcellenceSection/FireSafetyServiceExcellenceSection";
import FireSafetyServiceFAQsSection from "./FireSafetyServiceFAQsSection/FireSafetyServiceFAQsSection";
import FireSafetyOurCoreServiceSection from "./FireSafetyOurCoreServiceSection/FireSafetyOurCoreServiceSection";

const FireSafetyServices = () => {
  return (
    <div>
      <FireSafetyServiceBannerSection />
      <FireSafetyServiceOverviewSection />
      <FireSafetyOurServiceSection />
      <FireSafetyOurCoreServiceSection />
      <FireSafetyServiceExcellenceSection />
      {/* <FireSafetyServiceFAQsSection /> */}
      {/* <ClientSection /> */}
    </div>
  );
};

export default FireSafetyServices;
