import React from "react";
import "./ElectronicServiceOverviewSection.css";

const ElectronicServiceOverviewSection = () => {
  return (
    <>
      <div className="electronic_overview_main_container">
        <div className="electronic_overview_title">
          <p>OverView</p>
          <h3>Electronic Security Services</h3>
        </div>
        <div className="electronic_overview_content">
          <p>
            Under the umbrella of Checkmate Facility & Electronic Solutions Pvt
            Ltd (CFESPL), we serve a diverse range of electronic security
            services that portray a perfect consort of how technology can be
            leveraged to offer best of security. We perceive the significance of
            technologically empowered devices and solutions and that is what we
            emphasize on, as we serve our clients. Our balanced mix of tailored
            and pre-defined solutions are meant for industrial projects,
            educational institutions, hotels, hospitals, IT industries, cyber
            parks, defence sector, overseas customers etc. Carving an ideal
            scenario with equal involvement of man and machine, it includes the
            complete cycle starting from analysis, design, development till
            implementation.
            <br />
            <br />
            Our wide-ranging product portfolio includes Public Address/Voice
            Evacuation Systems, Conference Systems, Closed circuit television
            (CCTV) systems including state-of-the-art IP solutions, Intrusion
            Systems, Fire Alarm Systems, Access Control Systems, Nurse Call
            Systems, Two-way Radio Communication Systems, EPABX-Voice, Data
            Communication, Building Management System, Perimeter Protection
            Solutions, Gate Automation systems, Under Vehicle Scanning, X Ray
            Baggage & Door Frame Metal Detectors, and Car Park Management
            Systems. These systems are integrated seamlessly on mobile devices
            to ensure generation of insightful reports/dashboards. Quick
            Response Time (QRT) generation is included in services like GPS
            tracking and remote alarm monitoring, where deployment of static
            guards is not possible.
          </p>
        </div>
      </div>
    </>
  );
};

export default ElectronicServiceOverviewSection;
