import React from "react";
import "./AboutDirector.css";
import aboutdirectorimage from "../../../assets/images/about_section_director_image.png";

const AboutDirector = () => {
  return (
    <>
      <div className="about_director_main_container">
        <div className="about_director_left_side_section">
          <img src={aboutdirectorimage} alt="Company Managing Director" />
        </div>
        <div className="about_director_right_side_section">
          <div className="about_director_right_side_section_title">
            <h3>VIKRAM MAHURKAR</h3>
            <p>MANAGING DIRECTOR</p>
          </div>
          <div className="about_director_right_side_section_description">
            <ul>
              <li>
                The founder Mr. Vikram Mahurkar, starting early at the age of 20
                years with a tyre company and experiencing the Good Business
                Practices in Gujarat, the spirit of entrepreneurship was ignited
                to start a security company with a background of having the
                family in the Armed Forces. The spirit of discipline in all
                walks of life and good command control propelled the company to
                the next level in less than a decade.
                <li>
                  In the years of growth, the Checkmate family grew and came
                  together to create an Institution which adds value to the
                  common man, and contributes to the well-being of a nation,
                  resulting in sectoral growth.
                </li>
                <li>
                  {" "}
                  The imbibed qualities on a parallel note became part of the
                  work culture.
                </li>
                <li>
                  {" "}
                  From where we are today, the Company has a desire to add value
                  to the client who has been a partner in the journey with
                  technology and artificial intelligence. To create an
                  environment of mutual trust and security.
                </li>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutDirector;
