import React from "react";
import "./AboutExperience.css";
import CountUp from "react-countup";

const PageAboutExperience = ({
  experienceTitle,
  experienceDescription,
  locationsLabel,
  employeesLabel,
  clientsLabel,
  locationsCount,
  employeesCount,
  clientsCount,
}) => {
  const formatCount = (count) => {
    return count.replace(/[^\d]/g, ""); // Keep the original count string for display
  };
  return (
    <>
      <div className="about_experience_main_container">
        <div className="about_experience_content">
          <div className="about_experience_content_left_side">
            <div className="about_experience_content_left_side_text">
              <h6>{experienceTitle}</h6>
              <p>{experienceDescription}</p>
            </div>
          </div>
          <div className="about_experience_content_right_side">
            <div className="about_experience_content_right_side_1">
              <div className="about_experience_content_right_side_1_round">
                <span> <CountUp
            start={0}
            end={parseInt(formatCount(locationsCount))}
            duration={3}
            suffix={locationsCount.replace(formatCount(locationsCount), "")} 
          /></span>
              </div>
              <p>{locationsLabel}</p>
            </div>
            <div className="about_experience_content_right_side_2">
              <div className="about_experience_content_right_side_2_round">
                <span><CountUp
            start={0}
            end={parseInt(formatCount(employeesCount))}
            duration={3}
            suffix={employeesCount.replace(formatCount(employeesCount), "")} 
          /></span>
              </div>
              <p>{employeesLabel}</p>
            </div>
            <div className="about_experience_content_right_side_3">
              <div className="about_experience_content_right_side_3_round">
                <span><CountUp
            start={0}
            end={parseInt(formatCount(clientsCount))}
            duration={3}
            suffix={clientsCount.replace(formatCount(clientsCount), "")}
          /></span>
              </div>
              <p>{clientsLabel}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageAboutExperience;
