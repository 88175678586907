import React, { useState, useEffect, useRef } from "react";
import "./Awards.css";
import certificate from "../../../assets/images/about_section_awrads_certificate.png";
import certificate1 from "../../../assets/images/certificate_1.jpg";
import certificate2 from "../../../assets/images/certificate_2.jpg";
import certificate3 from "../../../assets/images/certificate_3.jpg";
import certificate4 from "../../../assets/images/certificate_4.jpg";
// import certificate5 from "../../../assets/images/certificate_5.jpg";

const Awards = () => {
  const scrollContainerRef = useRef(null);
  const isTouching = useRef(false);

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const scrollInterval = setInterval(() => {
      if (scrollContainer && !isTouching.current) {
        const scrollAmount = scrollContainer.scrollWidth / 4;
        scrollContainer.scrollBy({ left: scrollAmount, behavior: "smooth" });

        // If scrolled to end, reset to start
        if (
          scrollContainer.scrollLeft + scrollContainer.clientWidth >=
          scrollContainer.scrollWidth
        ) {
          scrollContainer.scrollLeft = 0;
        }
      }
    }, 3000);

    return () => clearInterval(scrollInterval); // Cleanup interval on component unmount
  }, []);

  const handleInteractionStart = () => {
    isTouching.current = true;
  };

  const handleInteractionEnd = () => {
    isTouching.current = false;
  };

  const openModal = (certificateImage) => {
    setSelectedCertificate(certificateImage);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCertificate(null);
  };

  return (
    <div className="awards-container">
      <div className="left-content">
        <h6>AWARDS &</h6>
        <h3>Accreditation</h3>
        <p>
          A symbolic representation of the trust our clients have in us and the
          successful journey over the years. Driving us to be at our best and
          keep marching forward!
        </p>
      </div>
      <div
        className="right-content"
        onMouseDown={handleInteractionStart}
        onMouseUp={handleInteractionEnd}
        onTouchStart={handleInteractionStart}
        onTouchEnd={handleInteractionEnd}
      >
        <div className="scrollable-container" ref={scrollContainerRef}>
          <div className="award-card" onClick={() => openModal(certificate1)}>
            <img src={certificate} alt="Certificate" />
            <h4>Capsi Membership</h4>
            <h6>Certified for</h6>
            <p>ENVIRONMENTAL MANAGEMENT SYSTEM</p>
          </div>

          <div className="award-card" onClick={() => openModal(certificate2)}>
            <img src={certificate} alt="Certificate" />
            <h4>Capsi Membership</h4>
            <h6>Certified for</h6>
            <p>QUALITY MANAGEMENT SYSTEM</p>
          </div>

          <div className="award-card" onClick={() => openModal(certificate3)}>
            <img src={certificate} alt="Certificate" />
            <h4>Capsi Membership</h4>
            <h6>Certified for</h6>
            <p>INFORMATION SECURITY MANAGEMENT SYSTEM</p>
          </div>

          <div className="award-card" onClick={() => openModal(certificate4)}>
            <img src={certificate} alt="Certificate" />
            <h4>Capsi Membership</h4>
            <h6>Certified for</h6>
            <p>OCCUPATIONAL HEALTH & SAFETY MANAGEMENT SYSTEM</p>
          </div>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="modal">
          {/* <div className="modal-content"> */}
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img
              src={selectedCertificate}
              alt="Certificate"
              className="modal-image"
            />
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default Awards;
