import React from "react";
import ContactUsForm from "./ContactUsForm/ContactUsForm";
import ContactTalkUs from "./ContactTalkUs/ContactTalkUs";

const ContactUs = () => {
  return (
    <div className="contact-us">
      <ContactUsForm />
      <ContactTalkUs />
    </div>
  );
};

export default ContactUs;
