import React from 'react'
import BannerCard from "../../../About/Banner/BannerCard";


const CaseMgmtServiceBannerSection = () => {
  return (
    <div>
        <BannerCard heading="Cash Management Services" />
    </div>
  )
}

export default CaseMgmtServiceBannerSection
