import React from "react";
import { Link } from "react-router-dom";


const CardOurCoreServices = ({
  imageSrc,
  altText,
  title,
  content,
  arrow_image,
  our_service_link,
}) => {
  return (
    <>
      <div className="our_core_services_card">
        <img
          className="our_core_services_card_icon"
          src={imageSrc}
          alt={altText}
        />
        <h6>{title}</h6>
        <p>{content}</p>

        <Link to={our_service_link}>
          <img
            className="our_core_services_card_arrow"
            src={arrow_image}
            alt="arrow image"
          />
        </Link>
      </div>
    </>
  );
};

export default CardOurCoreServices;
