import React from "react";
import BannerCard from "../../../About/Banner/BannerCard";

const EventMgmtServiceBannerSection = () => {
  return (
    <div>
      <BannerCard heading="Event Management Services" />
    </div>
  );
};

export default EventMgmtServiceBannerSection;
