import React from "react";
import ElectronicServiceBannerSection from "./ElectronicServiceBannerSection/ElectronicServiceBannerSection";
import ElectronicServiceOverviewSection from "./ElectronicServiceOverviewSection/ElectronicServiceOverviewSection";
import ElectronicOurCoreServiceSection from "./ElectronicOurCoreServiceSection/ElectronicOurCoreServiceSection";
import ElectronicOurServiceSection from "./ElectronicOurServiceSection/ElectronicOurServiceSection";
import ElectronicServiceExcellenceSection from "./ElectronicServiceExcellenceSection/ElectronicServiceExcellenceSection";

const ElectronicSecurityServices = () => {
  return (
    <div>
      <ElectronicServiceBannerSection />
      <ElectronicServiceOverviewSection />
      <ElectronicOurServiceSection/>
      <ElectronicOurCoreServiceSection/>
      <ElectronicServiceExcellenceSection/>
    </div>
  );
};

export default ElectronicSecurityServices;
