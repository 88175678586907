import React from "react";
import EventMgmtServiceBannerSection from "./EventMgmtServiceBannerSection/EventMgmtServiceBannerSection";
import EventMgmtServiceOverviewSection from "./EventMgmtServiceOverviewSection/EventMgmtServiceOverviewSection";
import EventMgmtOurServiceSection from "./EventMgmtOurServiceSection/EventMgmtOurServiceSection";
import EventMgmtOurCoreServiceSection from "./EventMgmtOurCoreServiceSection/EventMgmtOurCoreServiceSection";
import EventMgmtServiceExcellenceSection from "./EventMgmtServiceExcellenceSection/EventMgmtServiceExcellenceSection";

const EventMgmtServices = () => {
  return (
    <div>
      <EventMgmtServiceBannerSection />
      <EventMgmtServiceOverviewSection />
      <EventMgmtOurServiceSection />
      <EventMgmtOurCoreServiceSection />
      <EventMgmtServiceExcellenceSection />
    </div>
  );
};

export default EventMgmtServices;
