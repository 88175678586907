import React from "react";
import "./EventMgmtServiceOverviewSection.css";

const EventMgmtServiceOverviewSection = () => {
  return (
    <>
      <div className="event_mgmt_overview_main_container">
        <div className="event_mgmt_overview_title">
          <p>OverView</p>
          <h3>Event Management Services</h3>
        </div>
        <div className="event_mgmt_overview_content">
          <p>
            We create moments for you, we understand how important your events
            are for you. Leave the entire event management and security to us
            and sit back and enjoy. Our dedicated event management group has
            acquired specialized training in assessing all types of events and
            how differently all events are to be managed. Our portfolio of
            events includes all types of events – private, government, public,
            corporate, entertainment, sports, award functions, product launches,
            exhibitions and much more. We take up the entire process management
            starting from event planning, creating further logistic plans,
            budgets, marketing, lodging and boarding facilities, invitations,
            execution, to say the least.
            <br />
            <br />
            Our event management offering also include coordination with
            different external agencies like liaising with police, local
            authorities and emergency services. Our cost-effective security
            services also control the crowd that could create a hassle at
            certain events. Based on the type of event, our taskforce manages
            decorum and ensures complete security. We believe in training our
            security guards and our event managers in a detailed and
            professional manner, so that their learnings can be reflected in the
            assorted services that we offer.
          </p>
        </div>
      </div>
    </>
  );
};

export default EventMgmtServiceOverviewSection;
