import React from "react";
import BannerCard from "../../../About/Banner/BannerCard";

const SecurityServiceBannerSection = () => {
  return (
    <div>
      <BannerCard heading="Security Services" />
    </div>
  );
};

export default SecurityServiceBannerSection;
