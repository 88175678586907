import React from "react";
import CaseMgmtServiceBannerSection from "./CaseMgmtServiceBannerSection/CaseMgmtServiceBannerSection";
import CaseMgmtServiceOverviewSection from "./CaseMgmtServiceOverviewSection/CaseMgmtServiceOverviewSection";
import CaseMgmtOurServiceSection from "./CaseMgmtOurServiceSection/CaseMgmtOurServiceSection";
import CaseMgmtOurCoreServiceSection from "./CaseMgmtOurCoreServiceSection/CaseMgmtOurCoreServiceSection";
import CaseMgmtServiceExcellenceSection from "./CaseMgmtServiceExcellenceSection/CaseMgmtServiceExcellenceSection";

const CaseMgmtServices = () => {
  return (
    <div>
      <CaseMgmtServiceBannerSection />
      <CaseMgmtServiceOverviewSection />
      <CaseMgmtOurServiceSection />
      <CaseMgmtOurCoreServiceSection />
      <CaseMgmtServiceExcellenceSection />
    </div>
  );
};

export default CaseMgmtServices;

 