import React from "react";
import "./FireSafetyServiceOverviewSection.css";

const FireSafetyServiceOverviewSection = () => {
  return (
    <>
      <div className="fire_safety_service_overview_main_container">
        <div className="fire_safety_service_overview_title">
          <p>OverView</p>
          <h3>Fire & Safety Services</h3>
        </div>
        <div className="fire_safety_service_overview_content">
          <p>
            Looking at the key importance of training in fire and safety related
            activities, we provide certified, intense and realistic training,
            approved by the Government of Gujarat, for fireman, response
            professionals and sub fire officers. Powered by the Checkmate group,
            Institute of Fire Safety and Disaster Management Studies (IFSDMS)
            was founded in 1998.
            <br />
            <br />
            Over these years, we have been serving the emergency response and
            training needs of companies be it private sector or government
            agencies, through our comprehensive and customized training modules.
            In 2012, Hon. Shri Narendra Modi inaugurated a new zero discharge
            fire and safety training facility that is spread over acres of land.
            <br />
            <br />
            To provide high quality realistic internationally recognised
            training methodology, we collaborated with the erstwhile Fire
            Science Academy (FSA), University of Nevada, Reno, USA, having 40+
            years of experience in training many Fortune 100 companies in the
            petroleum, petrochemical, Chemical process and mining industries and
            fire & emergency services of several countries.
            <br />
            <br />
            Training is provided on Real Fuel, Live Fire, Hands-on Practical
            training on Industry size modular simulation props, being one of the
            few state-of-the-art institutes in the world. IFSDMS is a JOIFF
            accredited training provider and by virtue of which conducts JOIFF
            approved certificate courses.
            <br />
            <br />
            IFSDMS has a national accreditation for its affiliation with
            National Council of Vocational Training (NCVT), Ministry of Skill
            Development & Entrepreneurship, Govt. of India. We have signed an
            MoU with Rashtriya Raksha University- RRU- (under Ministry of Home
            Affairs) and sponsored men & women trainees for Fireman Trade.
          </p>
        </div>
      </div>
    </>
  );
};

export default FireSafetyServiceOverviewSection;
