import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import OurCoreServices from "./OurCoreServices/OurCoreServices";
import Experience from "./Experience/Experience";
import Testimonial from "./Testimonial/Testimonial";
import VideoSection from "./VideoSection/VideoSection";
import ClientSection from "./ClientSection/ClientSection";
import ChooseUs from "./ChooseUs/ChooseUs";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <OurCoreServices />
      <Experience />
      <Testimonial />
      <VideoSection />
      <ChooseUs />
      {/* <ClientSection /> */}
    </div>
  );
};

export default Home;
