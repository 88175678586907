import React from "react";
import "./Experience.css";
import "../../../assets/color/root_color.css";
import experience_features_icon1 from "../../../assets/icons/experience_features_icon1.svg";
import experience_features_icon2 from "../../../assets/icons/experience_features_icon2.svg";
import experience_features_icon3 from "../../../assets/icons/experience_features_icon3.svg";
import CountUp from "react-countup";

const Experience = () => {
  return (
    <>
      <div className="experience_main_container">
        <div className="experience_left_side">
          <div className="experience_left_side_top_overview">
            <p>A SNAPSHOT</p>
            <h3>20+ Years of Dedicated Services</h3>
          </div>
          <div className="experience_left_side_bottom_features">
            <div className="experience_left_side_bottom_feature">
              <div className="experience_left_side_bottom_feature_left_side_icon">
                <abbr title="25 Years of Mastering Cutting-Edge Security Techniques for Unmatched Protection.">
                  <img
                    src={experience_features_icon1}
                    alt="Experience_icon_1"
                  />
                </abbr>
              </div>
              <div className="experience_left_side_bottom_feature_right_side_text">
                <h6>Cutting-Edge Technologies</h6>
                <p>
                  We leverage nothing but the latest and best set of equipment,
                  gadgets, software, devices, methodologies to serve you
                </p>
              </div>
            </div>
            <div className="experience_left_side_bottom_feature">
              <div className="experience_left_side_bottom_feature_left_side_icon">
                <abbr title="Expertly Implementing the Latest security techniques for unparalleled protection.">
                  <img
                    src={experience_features_icon2}
                    alt="Experience_icon_2"
                  />
                </abbr>
              </div>
              <div className="experience_left_side_bottom_feature_right_side_text">
                <h6>In-Depth Professional Experience</h6>
                <p>
                  It has been over two decades and our venturing into every nook
                  and corner of India has made us resilient and thoroughly
                  experienced
                </p>
              </div>
            </div>
            <div className="experience_left_side_bottom_feature">
              <div className="experience_left_side_bottom_feature_left_side_icon">
                <abbr title="Nothing!!!!!!">
                  <img
                    src={experience_features_icon3}
                    alt="Experience_icon_3"
                  />
                </abbr>
              </div>
              <div className="experience_left_side_bottom_feature_right_side_text">
                <h6>Undying Spirit To Serve The Best</h6>
                <p>
                  We started our journey with a client-first approach and served
                  the best and we continue to maintain the same fervour
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="experience_right_side">
          <div className="experience_right_side_information">
            <div className="experience_right_side_information_1">
              <h6><CountUp start={0} end={100} delay={0} enableScrollSpy={true} startOnMount={true} scrollSpyOnce={true} />+</h6>
              <p>Our Location</p>
            </div>
            <div className="experience_right_side_information_2">
              <h6><CountUp start={0} end={40000} delay={0} enableScrollSpy={true} startOnMount={true} scrollSpyOnce={true} />+</h6>
              <p>Our Employees</p>
            </div>
            <div className="experience_right_side_information_3">
              <h6><CountUp start={0} end={6000} delay={0} enableScrollSpy={true} startOnMount={true} scrollSpyOnce={true} />+</h6>
              <p>Valuable Clients</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
