import React from "react";
import BannerCard from "../../../About/Banner/BannerCard";

const FireSafetyServiceBannerSection = () => {
  return (
    <>
      <BannerCard heading="Fire & Safety Services" />
    </>
  );
};

export default FireSafetyServiceBannerSection;
