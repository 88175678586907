import React from "react";
import PageAboutExperience from "../../../About/AboutExperience/PageAboutExperience";

const ElectronicServiceExcellenceSection = () => {
  const experienceTitle = "Fully Integrated and Robust Electronic Security ";
  const experienceDescription =
    "The perfect mix of contemporary technology and elaborate experience in the security arena – that is what makes us an ideal partner for any kind of electronic security requirement.";
  const locationsLabel = "Clients";
  const employeesLabel = "Trained Taskforce";
  const clientsLabel = "Customer Satisfaction ";
  const locationsCount = "XX+";
  const employeesCount = "XXX+";
  const clientsCount = ">95%";
  return (
    <div>
      <PageAboutExperience
        experienceTitle={experienceTitle}
        experienceDescription={experienceDescription}
        locationsLabel={locationsLabel}
        employeesLabel={employeesLabel}
        clientsLabel={clientsLabel}
        locationsCount={locationsCount}
        employeesCount={employeesCount}
        clientsCount={clientsCount}
      />
    </div>
  );
};

export default ElectronicServiceExcellenceSection;
