import React from "react";
import "./IndustriesOverviewSection.css";

const IndustriesOverviewSection = () => {
  return (
    <>
      <div className="industries_overview_main_container">
        <div className="industries_overview_title">
          <p>OverView</p>
          <h3>Industries</h3>
        </div>
        <div className="industries_overview_content">
          <p>
            In the era of transformation, the industrial landscape is expanding
            and so is the technology landscape. Requirements are evolving,
            challenges are increasing, and we have been seeing it all these
            years. Much of our capability has been leveraged in assisting a
            diverse set of industry segments, offering them a plethora of
            service offerings. We feel the pulse of the industry segments,
            understand their requirements and are apt in offering what they
            really need.
            <br />
            <br />
            Industry domains, today, are facing challenges like market
            segmentation, behavioural discrimination, security hassles,
            maintenance issues, automation hurdles, newer trends, resource
            optimization. Our experienced management and well-trained taskforce
            come out as an ideal partner when it comes to fulfilling different
            service categories. The underlying strength of ours lies in our
            competence to serve assorted needs, with the same fervour, sincerity
            and professionalism.
          </p>
        </div>
      </div>
    </>
  );
};

export default IndustriesOverviewSection;
