import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RouteComponent from "./routing/RouteComponent";

export default function App() {
  return (
    <>
      <RouteComponent />
    </>
  );
}
