import React from "react";
import BannerCard from "../../../About/Banner/BannerCard";

const ElectronicServiceBannerSection = () => {
  return (
    <div>
      <BannerCard heading="Electronic Security Services" />
    </div>
  );
};

export default ElectronicServiceBannerSection;
