import React from "react";
import "./VideoSection.css";

const DialogBox = ({ onClose }) => {
  return (
    <>
      <div className="dialog-overlay">
        <div className="dialog-box">
          <button className="dialog-close-btn" onClick={onClose}>
            ✕
          </button>
          <div className="dialog-content">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/LUgLOa6hN7Y?si=8mZywj-NY9MQlI4J&amp;controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default DialogBox;
