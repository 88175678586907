import React from "react";
import "./OurCoreServices.css";
import "../../../assets/color/root_color.css";
import CardOurCoreServices from "./CardOurCoreServices";
import event_management_icon from "../../../assets/icons/event_management_icon.svg";
import cash_management_icon from "../../../assets/icons/cash_management_icon.svg";
import security_services_icon from "../../../assets/icons/security_services_icon.svg";
import facility_management_icon from "../../../assets/icons/facility_management_icon.svg";
import fire_and_safety_icon from "../../../assets/icons/fire_and_safety_icon.svg";
import electronic_security from "../../../assets/icons/electronic_security_icon.svg";
import arrow from "../../../assets/icons/arrow_icon.svg";
import horizontal_line from "../../../assets/icons/horizontal_line.svg";
import vertical_line from "../../../assets/icons/vertical_line.svg";

const OurCoreServices = () => {
  return (
    <>
      <div className="our_core_services_main_container">
        <p className="our_core_services_p">WHAT WE DO</p>
        <h3 className="our_core_services_h3">Our Core Services</h3>
        <div className="our_core_services_cards">
          <CardOurCoreServices
            imageSrc={security_services_icon}
            altText={"Security Services"}
            title={"Security Services"}
            our_service_link={"/services/security-service"}
            content={
              "Empowered by our years of experience and expertise, our robust and well-trained security taskforce exhibit complete professionalism and strength."
            }
            arrow_image={arrow}
          />
          <img className="vertical_line1" src={vertical_line} alt="" />
          <CardOurCoreServices
            imageSrc={facility_management_icon}
            altText={"Facility Management "}
            title={"Facility Management "}
            our_service_link={"/services/facility-management-service"}
            content={
              "Facilitating all types of infrastructure in maintaining their premises, by providing modern-day equipment operated by an efficient team of skilled workforce."
            }
            arrow_image={arrow}
          />
          <img className="vertical_line2" src={vertical_line} alt="" />
          <CardOurCoreServices
            imageSrc={fire_and_safety_icon}
            altText={"Fire and Safety "}
            title={"Fire and Safety "}
            our_service_link={"/services/fire-safety-service"}
            content={
              "Your safety is our prime responsibility and our comprehensive fire safety measures, and training schedules are full proof in ensuring a well-crafted output."
            }
            arrow_image={arrow}
          />
          <img src={horizontal_line} className="horizontal_line1" alt="" />
          <CardOurCoreServices
            imageSrc={electronic_security}
            altText={"Electronic Security "}
            title={"Electronic Security "}
            our_service_link={"/services/electronic-security-service"}
            content={
              "Equipped with the latest gadgets and strategies, our electronic security solutions are ahead of time and garner complete safety solutions."
            }
            arrow_image={arrow}
          />
          <CardOurCoreServices
            imageSrc={cash_management_icon}
            altText={"Cash Management "}
            title={"Cash Management"}
            our_service_link={"/services/cash-management-service"}
            content={
              "Understanding cash as a highly sensitive commodity, our specialized services are based on RBI directives on Cash handling and experienced staff in cash transportation and processing."
            }
            arrow_image={arrow}
          />
          <CardOurCoreServices
            imageSrc={event_management_icon}
            altText={"Event Management "}
            title={"Event Management "}
            our_service_link={"/services/event-management-service"}
            content={
              "We turn your personal events into joy, corporate functions into complete client satisfaction and commercial experiences into profitable ventures."
            }
            arrow_image={arrow}
          />
        </div>
      </div>
    </>
  );
};

export default OurCoreServices;
