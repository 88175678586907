import React from "react";
import "./Banner.css";
import BannerCard from "./BannerCard";
const Banner = () => {
  return (
    <>
      <BannerCard heading="About Checkmate" />
    </>
  );
};

export default Banner;
